'use strict'

import 'babel-polyfill'
import moment from 'moment'
import UIKit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'

UIKit.use(Icons)

window.addEventListener('scroll', function(e) {
   let alpha = window.scrollY * 0.33
   if(alpha > 100) alpha = 100
   if(alpha < 0) alpha = 0

   var color = "rgba(0,0,0," + alpha/100 + ")";
   document.getElementById("navBg").style.opacity = alpha/100

})

Vue.directive('poster', {
  bind: function (el, binding, vnode) {
    var s = JSON.stringify
    el.style.backgroundImage = `url(${binding.value})`
    el.class = "uk-background-cover"
  }
})

var app = new Vue({
  el: '#app',
  data: {
     modal: false,
     activePhoto: '',
     safari: false
  },
  methods: {
    setActivePhoto(photo) {
        this.activePhoto = photo
    }
  },
  created() {
      var ua = navigator.userAgent.toLowerCase()
      var is_safari = (ua.indexOf("safari/") > -1 && ua.indexOf("chrome") < 0)
      if(is_safari) {
        this.safari = true
      }
  }
})


document.addEventListener("DOMContentLoaded", function() {

    var tag = document.createElement('script');
    tag.src = "https://www.youtube.com/player_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    // Replace the 'ytplayer' element with an <iframe> and
    // YouTube player after the API code downloads.
    var player;
    function onYouTubePlayerAPIReady() {
      player = new YT.Player('player', {
        height: '360',
        width: '640',
        videoId: '2WQpx5zavZ8',
        controls: 0
      });
    }

})
